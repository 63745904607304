import { Backup } from 'api/api'
import { Table } from '@it-incubator/ui-kit'
import s from './backup-table.module.css'
import { TableRow } from 'components/backup/backup-table/table-row/table-row'

type BackupTableProps = {
  backups: Backup[]
}

export const BackupTable = ({ backups }: BackupTableProps) => {
  return (
    <Table.Root className={s.table}>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Last backup date</Table.HeadCell>
          <Table.HeadCell>Next backup date</Table.HeadCell>
          <Table.HeadCell>Check date</Table.HeadCell>
          <Table.HeadCell>Last status</Table.HeadCell>
          <Table.HeadCell>Last size in mb</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {backups.map((backup) => (
          <TableRow key={backup._id} backup={backup} />
        ))}
      </Table.Body>
    </Table.Root>
  )
}
