import { Chip, Modal, Spinner, Table } from '@it-incubator/ui-kit'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Backup, backupAPI } from 'api/api'
import s from './history-modal.module.css'
import { queryClient } from 'App'
import { useContext } from 'react'
import { BackupContext } from 'components/backup/backup'

export const HistoryModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const historyId = searchParams.get('history')
  const isOpen = Boolean(historyId)
  const dataBaseType = useContext(BackupContext)

  const { data, isFetching } = useQuery(['history', historyId], () => backupAPI.fetchHistory(historyId!), {
    enabled: Boolean(historyId),
  })

  const backup = queryClient
    .getQueryData<{ data: Backup[] }>(['backup', dataBaseType])
    ?.data?.find((backup) => backup._id === historyId)

  if (!isOpen) {
    return null
  }

  const handleClose = () => {
    searchParams.delete('history')
    setSearchParams(searchParams)
  }

  return (
    <Modal open={isOpen} onClose={handleClose} size="lg" title={`${backup?.name}`} className={s.modal}>
      {isFetching && <Spinner />}
      {data && !isFetching && (
        <Table.Root className={s.table}>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Message</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Size</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.data.map(({ size, message, date, status, databaseId }, index) => {
              const labelColor = status === 'SUCCESS' ? 'green' : status === 'WARNING' ? 'blue' : 'brown'
              return (
              <Table.Row key={index}>
                <Table.Cell>{new Date(date).toLocaleString('ru-Ru')}</Table.Cell>
                <Table.Cell>{message}</Table.Cell>
                <Table.Cell>
                  {status && <Chip label={status} color={labelColor} />}
                </Table.Cell>
                <Table.Cell>{size}</Table.Cell>
              </Table.Row>
            )})}
          </Table.Body>
        </Table.Root>
      )}
    </Modal>
  )
}
