import { Chip, Refresh, Table, Typography } from '@it-incubator/ui-kit'
import { Backup, backupAPI } from 'api/api'
import { useMutation } from 'react-query'
import { useContext } from 'react'
import { BackupContext } from 'components/backup/backup'
import { queryClient } from 'App'
import s from './table-row.module.css'
import { useSearchParams } from 'react-router-dom'
import { checkIsToday } from 'common/utils/checkIsToday'

type TableRowProps = {
  backup: Backup
}

export const TableRow = ({
  backup: { _id, name, nextBackupDate, lastBackupDate, checkDate, lastBackupStatus, lastBackupSizeInMegabytes },
}: TableRowProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { mutate, isLoading } = useMutation(backupAPI.backupNull, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['backup'])
      }, 200)
    },
  })
  const dateBase = useContext(BackupContext)!

  const handleBackupNullButtonClick = () => {
    mutate({ id: _id, type: dateBase })
  }

  const handleNameButtonClick = () => {
    searchParams.set('history', _id)
    setSearchParams(searchParams)
  }

  const labelColor = lastBackupStatus === 'SUCCESS' ? 'green' : lastBackupStatus === 'WARNING' ? 'blue' : 'brown'

  const isToday = lastBackupDate && checkIsToday(lastBackupDate)
  const rowClassName = isToday ? s.today : ''

  return (
    <Table.Row className={rowClassName}>
      <Table.Cell>
        <div className={s.nameCellContent}>
          <button onClick={handleNameButtonClick} className={s.buttonName}>
            {<Typography.Body1 className={s.name}>{name}</Typography.Body1>}
          </button>
          <button onClick={handleBackupNullButtonClick} disabled={isLoading} className={s.button}>
            <Refresh />
          </button>
        </div>
      </Table.Cell>
      <Table.Cell>{lastBackupDate && new Date(lastBackupDate).toLocaleString('ru-Ru')}</Table.Cell>
      <Table.Cell>{nextBackupDate && new Date(nextBackupDate).toLocaleString('ru-Ru')}</Table.Cell>
      <Table.Cell>{checkDate && new Date(checkDate).toLocaleString('ru-Ru')}</Table.Cell>
      <Table.Cell>{lastBackupStatus && <Chip label={lastBackupStatus} color={labelColor} />}</Table.Cell>
      <Table.Cell>{lastBackupSizeInMegabytes}</Table.Cell>
    </Table.Row>
  )
}
