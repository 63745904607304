export const loadState = (name: string) => {
  try {
    const savedState = localStorage.getItem(name)

    if (savedState === null) {
      return undefined
    }

    return JSON.parse(savedState)
  } catch (e) {
    return undefined
  }
}

export const saveState = (name: string, data: string) => {
  try {
    const stateToBeSaved = JSON.stringify(data)

    localStorage.setItem(name, stateToBeSaved)
  } catch (e) {
    console.error(e)
  }
}
