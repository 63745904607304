import s from 'components/backup/header/header.module.css'
import { AddCircleOutline, Select, Spinner, Typography } from '@it-incubator/ui-kit'
import { DataBase } from 'api/api'
import { selectOptions } from 'common/constants'
import { useSearchParams } from 'react-router-dom'
import { useContext } from 'react'
import { BackupContext } from 'components/backup/backup'

type HeaderProps = {
  isLoading: boolean
  selectedDataBase: DataBase
  setSelectedDateBase: (value: DataBase) => void
}

export const Header = ({ isLoading, selectedDataBase, setSelectedDateBase }: HeaderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const DataBaseType = useContext(BackupContext)

  const handleAddButtonClick = () => {
    searchParams.set('add-modal', DataBaseType!)
    setSearchParams(searchParams)
  }

  return (
    <header className={s.header}>
      <div className={s.titleBox}>
        <Typography.H1>Backups</Typography.H1>
        {isLoading && (
          <div className={s.spinner}>
            <Spinner size={30} fullScreen={false} />
          </div>
        )}
      </div>
      <div className={s.selectBox}>
        <button className={s.addButton} onClick={handleAddButtonClick}>
          <AddCircleOutline className={s.addIcon} />
        </button>
        <Select
          options={selectOptions}
          value={selectedDataBase}
          onChange={(value: string) => setSelectedDateBase(value as DataBase)}
        />
      </div>
    </header>
  )
}
