import { useSearchParams } from 'react-router-dom'
import { Button, Modal, TextField } from '@it-incubator/ui-kit'
import { useForm } from 'react-hook-form'
import { backupAPI, DataBase } from 'api/api'
import { useMutation } from 'react-query'
import { queryClient } from 'App'
import s from './add-service-modal.module.css'

type FormData = {
  name: string
  userName: string
  url: string
  port: number
  host: string
}

export const AddServiceModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dataBaseType = (searchParams.get('add-modal') as DataBase) || null
  const isOpen = Boolean(dataBaseType)

  const { mutate: mutateMongo } = useMutation(backupAPI.addMongoService, {
    onSuccess: () => {
      queryClient.invalidateQueries(['backup'])
      handleCloseModal()
    },
  })

  const { mutate: mutatePostgres } = useMutation(backupAPI.addPostgresService, {
    onSuccess: () => {
      queryClient.invalidateQueries(['backup'])
      handleCloseModal()
      reset()
    },
  })

  const handleCloseModal = () => {
    searchParams.delete('add-modal')
    setSearchParams(searchParams)
    reset()
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({})

  const onSubmit = (formData: FormData) => {
    if (dataBaseType === 'mongo') {
      mutateMongo({
        name: formData.name,
        url: formData.url,
      })
    }

    if (dataBaseType === 'postgres') {
      mutatePostgres({
        host: formData.host,
        port: formData.port,
        name: formData.name,
        username: formData.userName,
      })
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal title={`Add ${dataBaseType} service`} open={isOpen} onClose={handleCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.controls}>
          <TextField
            label="Name"
            {...register('name', { required: 'The field is required' })}
            errorMessage={errors.name?.message}
            autoComplete="off"
          />
          {dataBaseType === 'postgres' && (
            <TextField
              label="Username"
              {...register('userName', { required: 'The field is required' })}
              errorMessage={errors.userName?.message}
            />
          )}
          {dataBaseType === 'postgres' && (
            <TextField
              label="Port"
              errorMessage={errors.port?.message}
              {...register('port', { required: 'The field is required' })}
            />
          )}
          {dataBaseType === 'postgres' && (
            <TextField
              label="Host"
              {...register('host', { required: 'The field is required' })}
              errorMessage={errors.host?.message}
            />
          )}
          {dataBaseType === 'mongo' && (
            <TextField
              label="URL"
              {...register('url', { required: 'The field is required' })}
              errorMessage={errors.url?.message}
            />
          )}
        </div>

        <Button disabled={isSubmitting} type="submit" className={s.button}>
          {`Add ${dataBaseType} service`}
        </Button>
      </form>
    </Modal>
  )
}
