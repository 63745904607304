import { loadState, saveState } from 'common/utils/local-storage'

/**
 * get admin-token from URL otherwise from localStorage
 **/
export const getAdminTokenFromUrlOrLS = () => {
  let token = loadState('admin-token')

  const queryParamsString = window.location.search

  const searchParams = new URLSearchParams(queryParamsString)
  const tokenFromURl = searchParams.get('admin-token')

  if (tokenFromURl) {
    token = tokenFromURl
    saveState('admin-token', token)
  }

  return token
}
