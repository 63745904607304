export const checkIsToday = (dateString: string) => {
  const today = new Date()
  const dateToCheck = new Date(dateString)

  return (
    today.getUTCFullYear() === dateToCheck.getUTCFullYear() &&
    today.getUTCMonth() === dateToCheck.getUTCMonth() &&
    today.getUTCDate() === dateToCheck.getUTCDate()
  )
}
