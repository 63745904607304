import './App.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Backup } from 'components/backup/backup'

export const queryClient = new QueryClient()

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <main className="App">
        <div className="container">
          <Backup />
        </div>
      </main>
    </QueryClientProvider>
  )
}
