import axios from 'axios'
import { getAdminTokenFromUrlOrLS } from 'common/utils/getAdminToken'

const token = getAdminTokenFromUrlOrLS()

const instance = axios.create({
  // withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_API || 'https://backup.staging.it-incubator.ru/api/',
  headers: {
    'admin-token': token,
  },
})

export const backupAPI = {
  fetchData(type: DataBase) {
    return instance.get<Backup[]>(`backup/${type}`)
  },
  backupNull({ type, id }: BackupNullParams) {
    return instance.patch<boolean>(`backup/${type}/${id}`)
  },
  addMongoService(body: AddMongoServiceParams) {
    return instance.post(`backup/mongo`, body)
  },
  addPostgresService(body: AddPostgresServiceParams) {
    return instance.post(`backup/postgres`, body)
  },
  fetchHistory(id: string) {
    return instance.get<History[]>(`history/${id}`)
  },
}

export type DataBase = 'postgres' | 'mongo'

export type Backup = {
  lastBackupSizeInMegabytes?: null | number
  _id: string
  url: string
  name: string
  nextBackupDate?: string
  checkDate: string
  lastBackupDate?: string
  lastBackupStatus?: 'SUCCESS' | 'ERROR' | 'WARNING'
}

type BackupNullParams = {
  type: DataBase
  id: string
}

type AddMongoServiceParams = {
  name: string
  url: string
}

type AddPostgresServiceParams = {
  name: string
  username: string
  port: number
  host: string
}

export type History = {
  databaseId: string
  date: string
  status: string
  message: string
  fileUrl: string
  size: number
}
