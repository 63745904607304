import { createContext, useState } from 'react'
import { backupAPI, DataBase } from 'api/api'
import { useQuery } from 'react-query'
import { BackupTable } from './backup-table/backup-table'
import { Header } from './header/header'
import { AddServiceModal } from 'components/backup/add-service-modal/add-service-modal'
import { HistoryModal } from 'components/backup/history-modal/history-modal'

export const BackupContext = createContext<DataBase | null>(null)

export const Backup = () => {
  const [selectedDataBase, setSelectedDateBase] = useState<DataBase>('mongo')
  const { data, isFetching } = useQuery(['backup', selectedDataBase], () => backupAPI.fetchData(selectedDataBase))

  return (
    <div>
      <BackupContext.Provider value={selectedDataBase}>
        <Header isLoading={isFetching} selectedDataBase={selectedDataBase} setSelectedDateBase={setSelectedDateBase} />
        {data && <BackupTable backups={data.data} />}
        <AddServiceModal />
        <HistoryModal />
      </BackupContext.Provider>
    </div>
  )
}
